import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="m 41.953231,24.027839 v 25.394067 c 0,1.865684 -0.449195,3.316489 -1.347487,4.352982 -0.898295,1.036489 -2.159261,1.5552 -3.783094,1.5552 -1.45109,0 -2.677296,-0.414968 -3.67924,-1.244162 -1.001939,-0.829193 -1.727606,-2.073038 -2.176752,-3.731426 l -6.42641,2.643313 c 0.863743,2.798526 2.384062,4.992116 4.560695,6.581403 2.211182,1.589286 4.784981,2.383939 7.721707,2.383939 1.692933,0 3.265125,-0.275942 4.716215,-0.828739 1.485638,-0.518245 2.763715,-1.295783 3.834757,-2.332275 1.105591,-1.071041 1.969754,-2.383877 2.591649,-3.938613 0.656445,-1.589289 0.984257,-3.420306 0.984257,-5.493288 V 24.027839 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
